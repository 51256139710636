<template>
  <div>
    <p>
      <b>Release Date</b>
      <br>•  The Ninjago: Masters of Spinjitzu release, including the changes below, is expected to release Tuesday, September 20, 2011.
    </p>
    <p>
      <b>Level Cap</b>
      <br>•  The Level Cap has been raised to Level 45.
      <br>•  Upon reaching Level 45, players will now return from being smashed with 8 Life and 20 Imagination, as opposed to the normal 4 Life and 6 Imagination.
    </p>
    <p>
      <b>Ninjago Monastery Zone</b>
      <br>•  Minifigures who have joined the Nexus Force will have access to the Ninjago Monastery zone. They can launch to the Monastery from Nexus Tower.
      <br>•  This zone introduces 4 new versions of Spinjitzu – Earth, Lightning, Ice and Fire. Minifigures must learn these by completing specific Missions for Cole, Jay, Zane, Kai and Sensei Wu.
      <br>•  The Ninjago Monastery Zone contains over 86 new Missions and over 60 new Achievements. 13 new Daily Missions have also been added. Please note that Sensei Wu's Daily Missions have very exciting and rare rewards!
      <br>•  Skeletons armed with Maelstrom weapons are attacking the Monastery. Some have new abilities such as poison and stun.
      <br>•  Each element of Spinjitzu is taught in a specific Dojo, which features themed gardens outside and a puzzle-filled Attic in the space above each Dojo.
      <br>•  The Bone Wolf prowls the Ninjago Caves underneath the Monastery.
      <br>•  Using the new versions of Spinjitzu, Minifigures can now travel between Ninjago lanterns, allowing for quicker movement in the Ninjago Monastery zone.
      <br>•  New puzzles have been introduced, including those that may require two players to complete. Such puzzles are marked with an icon showing two Minifigure heads.
      <br>•  After completing a few Missions, Minifigures can speak to Johnny Umami in the Courtyard to learn more about Cooking. By using certain items, Johnny Umami can create more powerful consumable items.
      <br>•  Minifigures will be able to face off against Skeleton enemies from the Ninjago toy line. Smash Chopov, Bonezai and Krazi to prevent them from finding the Golden Weapons of Spinjitzu!
      <br>•  1 to 4 Minifigures will be able to take on Frakjaw in his own Battle Instance. This instance scales in difficulty with the number of Minifigures who attempt it. This is a mid-Level encounter.
      <br>•  Many new Smashables have been added, some of which can be rebuilt and used against the Skeletons.
      <br>•  Over 50 new items have been added to this zone, including the Ninjago Hound Treat, Thought Blade, and the Sushi Delivery System.
      <br>•  Some powerful Elite weapons can be created by purifying Maelstrom Weapons you claim from the Skeletons. Visit Nya in the Monastery to begin this Mission chain.
      <br>•  New "Ninjago Monastery" models are available from Bubu Mumu, the Monastery's Models Vendor.
      <br>•  New "Skeleton Outpost" models are available as drops from smashing Skeletons and Skeleton supplies.
      <br>•  Minifigures can now tame the Rock Dragon Pet after completing certain missions in the Ninjago Monastery.
    </p>
    <p>
      <b>Other Changes</b>
      <br>•  The pet taming item for the Tortoise pet has been changed.
      <br>•  Players can now single-click items in the Action Bar to use them (this can be returned to the original functionality in the Options menu).
      <br>•  Trade changes detailed in <router-link :to="{ name: 'story', params: {id: 348161} }">this article</router-link> are now active
      <br>•  Resizing the backpack will now stay at its new size in-between game sessions.
      <br>•  Look for Shouty McBullhorn in Nimbus Plaza and start the Nexus Force Championship Mission chain!
      <br>•  Improvements to double-clicking items in your backpack.
      <br>•  Named enemies in Crux Prime should spawn as more often.
      <br>•  Minifigures can now double jump when falling.
      <br>•  The Nexus Force Championships begin on October 1.
    </p>
    <p>
      <b>Known issues</b>
      <br>•  On low end computers, the door between Sensei Wu’s courtyard and the storage room may still appear after it has been smashed. Minifigures can walk through this door after it has been smashed, even if it still appears built.
    </p>
    <p>
      Discuss the release notes
      <router-link to="/messageboards/4280079"> on the message boards!</router-link>
    </p>
  </div>
</template>
